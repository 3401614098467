<template>
  <div class="w-100 transition">
    <GenericReport
      title="Relatório de Não Conformidades"
      :route="route"
      :topFilters="topFilters"
      :sideFilters="sideFilters"
      :fields="fields"
      tipoRelatorio="relatorioNaoConformidades"
      fieldsFiltered
    />
  </div>
</template>

<script>
import moment from 'moment';
import GenericApi from '@/services/genericRequest';
import GenericReport from '@/components/Table/GenericReport';

export default {
  name: 'relatorioNaoConformidades',
  components: {
    GenericReport,
  },
  data() {
    return {
      route: 'report/naoConformidades',
      topFilters: {
        id_unidade: {
          type: 'genericSelect',
          route: 'unidade',
          defaultValue: null,
          firstOption: {
            value: null,
            label: 'Unidade Origem',
          },
        },
        id_setor: {
          type: 'select',
          defaultValue: null,
          firstOption: {
            value: null,
            label: 'Setor Origem',
          },
        },
        date: {
          type: 'rangeDate',
          defaultInitDate: moment().subtract(7, 'days'),
          defaultEndDate: moment(),
        },
        tipoNaoConformidade: {
          type: 'select',
          firstOption: {
            value: null,
            label: 'Não Conformidade',
          },
          options: [
            { value: 'quantidade_pecas_diferente', text: 'Extravios' },
            { value: 'perfuro_cortante', text: 'Perfuro Cortante' },
            { value: 'materiais_defeituosos', text: 'Defeito' },
            { value: 'desincrustante', text: 'Desincrustante' },
            { value: 'validade_vencida', text: 'Validade Vencida' },
          ],
        },
      },
      sideFilters: {
        id: {
          type: 'input',
          label: 'ID',
        },
        descricao: {
          type: 'input',
          label: 'Descrição',
        },
        id_tipo_material: {
          type: 'genericSelect',
          label: 'Tipo de Material',
          route: 'tipoMaterial',
        },
        area: {
          type: 'genericSelect',
          label: 'area',
          route: 'subSetor',
        },
      },
      fields: [
        {
          label: 'Tipo de material',
          key: 'tipoMaterial',
        },
        {
          label: 'ID',
          key: 'shownId',
        },
        {
          label: 'Descrição',
          key: 'descricao',
        },
        {
          label: 'Data/Hora',
          key: 'createdAt',
          formatter: (value) => moment(value).format('DD/MM/YY HH:mm'),
        },
        {
          label: 'Justificativa',
          key: 'justificativa',
        },
        {
          label: 'Usuário',
          key: 'usuario',
        },
        {
          label: 'Peças Cadastradas',
          key: 'quantidade_itens',
        },
        {
          label: 'Peças Recebidas',
          key: 'quantidade_pecas',
        },
        {
          label: 'Pérfuro(s) Cortante(s)',
          key: 'perfurosCortantes',
          formatter: (values) => (values[0] ? values.map((x, i) => `${i + 1}.${x.nome}`).join('\n') : '-'),
        },
        {
          label: 'Defeito(s)',
          key: 'defeitos',
          formatter: (values) => (values[0] ? values.map((x, i) => `${i + 1}.${x.nome}`).join('\n') : '-'),
        },
        {
          label: 'Desincrustante',
          key: 'desincrustante',
          formatter: (value) => {
            if (value === null) return '-';
            return (value ? 'Sim' : 'Não');
          },
        },
        {
          label: 'Validade Vencida',
          key: 'validade_vencida',
          formatter: (value) => {
            if (value === null) return '-';
            return (value ? 'Sim' : 'Não');
          },
        },
        {
          label: 'Não Conforme Inox',
          key: 'nao_conformidades_inox',
          formatter: (values) => ((values && values[0]) ? values.map((x, i) => `${i + 1}.${x}`).join('\n') : '-'),
        },
        {
          label: 'Unidade Origem',
          key: 'unidadeOrigem',
          formatter: (value) => value && value.nome,
        },
        {
          label: 'Setor Origem',
          key: 'setorOrigem',
          formatter: (value) => value && value.nome,
        },
        {
          label: 'Área',
          key: 'area',
        },
        {
          label: 'Fotos',
          key: 'fotos',
        },
      ],
      configuracaoRelatorio: null,
      fieldsFiltered: null,
    };
  },

  async mounted() {
    this.configuracaoRelatorio = await GenericApi.get({},
      'configuracaoRelatorio/RELATORIONAOCONFORMIDADES');

    this.getOptionsReport();
    this.getFieldsReport();
  },

  methods: {
    getOptionsReport() {
      const { options } = this.configuracaoRelatorio;
      if (Array.isArray(options)) {
        if (options[0].topFilters) {
          this.topFilters = options[0].topFilters;
          if (this.topFilters.date) {
            this.topFilters.date.defaultInitDate = moment().subtract(7, 'days');
            this.topFilters.date.defaultEndDate = moment();
          }
        }

        if (options[0].sideFilters) {
          this.sideFilters = options[0].sideFilters;
        }
      }
    },
    getFieldsReport() {
      const fieldsFilter = this.configuracaoRelatorio.fields || null;

      if (fieldsFilter && fieldsFilter.length) {
        this.fields.forEach((field, idx) => {
          if (!fieldsFilter.includes(field.label)) {
            this.fields.splice(idx, 1);
          }
        }, []);
        this.fieldsFiltered = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color-50: #209f85;

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transition {
  transition: 0.3s ease-in-out;
}

#report h2 {
  margin-bottom: 30px;
}

#report table {
  background-color: #FFF;
}

#report table thead th {
  color: #7E829F;
  padding-bottom: 50px;
  font-weight: 900;
}

</style>
